/* You can add global styles to this file, and also import other style files */
.btn {
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.dataTable-table td, .dataTable-table thead th, .table td, .table thead th {
  vertical-align: top;
}

.form-control::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
  
  opacity: 0.2 !important; /* Firefox */
}
*::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  
  opacity: 0.5 !important; /* Firefox */
}

.btn:disabled, fieldset:disabled .btn {
  opacity: 0.20 !important;
}